import React from "react";
import "./youtubeGalleryContent.css";
import cameraIcon from "./../../assets/graycamera.png";
import dronIcon from "./../../assets/graydrone.png";
import lensIcon from "./../../assets/graylens.png";
import phoneIcon from "./../../assets/grayphone.png";
import rigIcon from "./../../assets/grayrig.png";

function VideoLegend(props: { video: any }) {
  return (
    <>
      {props.video.equipment &&
        props.video.equipment.map((item: { type: string; text: string, _key: string }) => (
          <div className="LegendItem" key={item._key}>
            <div className="IconLabel">{item.text}</div>
            <div className="GrayIcon">
              {item.type === "drone" && <img alt="ikona drona" src={dronIcon}></img>}
              {item.type === "camera" && <img alt="ikona aparatu" src={cameraIcon}></img>}
              {item.type === "phone" && <img alt="ikona telefonu" src={phoneIcon}></img>}
              {item.type === "rig" && <img alt="ikonga statywu" src={rigIcon}></img>}
              {item.type === "lens" && <img alt="ikona obiektywu" src={lensIcon}></img>}
            </div>
          </div>
        ))}
    </>
  );
}

export default VideoLegend;
