import React, { useEffect, useRef, useState } from "react";
import "./youtubeGalleryContent.css";
import { PortableText } from "@portabletext/react";
import VideoLegend from "./videoLegend";

function FacebookVideo(props: { video: any }) {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const elementRef = useRef(null);
  useEffect(() => {
    if (elementRef.current && elementRef.current["clientHeight"]) {
      setTimeout(() => {
        setHeight(elementRef.current ? elementRef.current["clientHeight"] : 11);
        setWidth(elementRef.current ? elementRef.current["clientWidth"] : 11);
      }, 1000);
    }
  }, []);

  return (
    <>
      <div className="VideoCardContainer" key={props.video._id}>
        <div className="VideoCard">
          <div className="Video" ref={elementRef}>
            <iframe
              title="facebook_video"
              src={
                "https://www.facebook.com/plugins/video.php?height=" +
                height +
                "&href=" +
                encodeURIComponent(props.video.url) +
                "&width=" +
                width +
                "&show_text=false&t=0"
              }
              height={height}
              width={width}
              allowFullScreen
              allow="clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
          <div className="VideoDescription">
            <h2>{props.video.title}</h2>
            <div>
              <PortableText value={props.video.description} />
              <VideoLegend video={props.video} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FacebookVideo;
