import { createRef } from "react";
import AboutPage from "./pages/about/aboutPage";
import PageNotFound from "./pages/pageNotFound/pageNotFound";
import YoutubeGalleryContent from "./pages/portfolio/youtubeGalleryContent";
import ContactForm from "./pages/contact/pageContact";

export const AppRoutes = [
    { path: "/", name: "Home", element: <AboutPage />, nodeRef: createRef<HTMLInputElement>() },
    {
      path: "/about",
      name: "About",
      element: <AboutPage />,
      nodeRef: createRef<HTMLInputElement>(),
    },
    {
      path: "/portfolio",
      name: "Portfolio",
      element: <YoutubeGalleryContent />,
      nodeRef: createRef<HTMLInputElement>(),
    },
        {
      path: "/contact",
      name: "Contact",
      element: <ContactForm/>,
      nodeRef: createRef<HTMLInputElement>(),
    },
    {
      path: "*",
      name: "404",
      element: <PageNotFound />,
      nodeRef: createRef<HTMLInputElement>(),
    },
  ];