import React from "react";
import "./header.css";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="HeaderContainer">
      <div className="Logo"></div>
      <div className="MenuLink">
        <Link to="/">
          <div> O&nbsp;mnie </div>
        </Link>
      </div>
      <div className="MenuLink">
        <Link to="/portfolio">
          <div> Realizacje</div>
        </Link>
      </div>
      <div className="MenuLink">
        <Link to="/contact">
          <div> Kontakt</div>
        </Link>
      </div>
    </div>
  );
}

export default Header;
