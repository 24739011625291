import React, { useContext } from "react";
import "./youtubeGalleryContent.css";
import { DataContext } from "../../App";
import { PortableText } from "@portabletext/react";
import VideoLegend from "./videoLegend";
import FacebookVideo from "./facebook";

function YoutubeGalleryContent(props: any) {
  const { youtubeVideos } = useContext(DataContext);
  return (
    <>
      {youtubeVideos.map(
        (video: {
          url: string;
          title: string;
          type: string;
          description: [];
          equipment: [{ type: string; text: string }];
          _id: string;
        }) => {
          switch (video.type) {
            case "youtube":
              return (
                <div className="VideoCardContainer" key={video._id}>
                  <div className="VideoCard">
                    <div className="Video">
                      <iframe
                        title="youtube_video"
                        allow="fullscreen;"
                        loading="lazy"
                        src={video.url}
                      ></iframe>
                    </div>
                    <div className="VideoDescription">
                      <h2>{video.title}</h2>
                      <div>
                        <PortableText value={video.description} />

                        <VideoLegend video={video} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            case "tiktok":
              return (
                <div className="TikTokVideoCardContainer" key={video._id}>
                  <div className="VideoCard">
                    <div className="TikTokVideoDescription">
                      <h2>{video.title}</h2>
                      <div>
                        <PortableText value={video.description} />
                        <VideoLegend video={video} />
                      </div>
                    </div>
                    <div className="TikTokVideo">
                      <iframe
                        title={"youtube"}
                        loading="lazy"
                        src={video.url}
                        className="TikTokIframe"
                        allowFullScreen
                        scrolling="no"
                        allow="encrypted-media;"
                      ></iframe>
                    </div>
                  </div>
                </div>
              );
            case "facebook":
              return <FacebookVideo video={video}></FacebookVideo>;
            default:
              return <></>;
          }
        }
      )}
    </>
  );
}

export default YoutubeGalleryContent;
