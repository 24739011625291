import React, { useContext } from "react";
import logo from "./../../assets/piotrek_logo.png";
import "./footer.css";
import { DataContext } from "../../App";
import { PortableText } from "@portabletext/react";

function Footer() {
  const { footer } = useContext(DataContext);

  return (
    <div className="FooterContainer">
      <div className="FooterSection">
      <PortableText value={footer.textSection} />
      </div>
      <div className="FooterSection BottomAligned">
        <img alt='Logo firmy cookielookie' src={logo} className="AppLogo"  />
        <span className="TextSmall"> {footer.copyright}</span>
      </div>
    </div>
  );
}

export default Footer;
