import React, { useContext } from "react";
import "./aboutPage.css";

import { DataContext } from "../../App";
import imageUrlBuilder from "@sanity/image-url";
import { SanityConfig } from "../../sanity";

import { PortableText } from "@portabletext/react";

function AboutPage() {
  const { aboutMe, customerLogos } = useContext(DataContext);
  const builder = imageUrlBuilder(SanityConfig);

  function urlFor(source: string) {
    if (source) {
      return builder.image(source);
    }
    return null;
  }
  const profilePic = urlFor(aboutMe.url);

  return (
    <div className="AboutPage">
      <div className="PhotoCardContainer">
        <div className="PhotoCard">
          <div
            className="Photo"
            style={{
              backgroundImage: `url(${
                profilePic != null ? profilePic.width(500).url() : ""
              })`,
            }}
          >
            <div className="Empty"></div>
          </div>
          <div className="Description">
            <h1>{aboutMe.title}</h1>
            <PortableText value={aboutMe.description} />
          </div>
        </div>
      </div>
      {customerLogos.length > 0 && (
        <>
          <div className="SubHeaderSmall">Zadowoleni klienci: </div>

          <div className="CustomersLogos">
            {customerLogos.map((customer: { picture: string, _id: string, name: string }) => (
              <div className="CustomerLogo" key={customer._id}>
                <img alt={'logo firmy ' + customer.name} src={urlFor(customer.picture)?.url()}></img>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AboutPage;
