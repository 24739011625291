import React, { useEffect, useState } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";
import "./mainLayout.css";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { AppRoutes } from "../../router";
import { DataContext } from "../../App";
import logo from "./../../assets/piotrek_logo.png";
import { SanityConfig } from "../../sanity";

function MainLayout() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  const currentOutlet = useOutlet();
  const { nodeRef } =
    AppRoutes.find((route) => route.path === location.pathname) ?? {};

  const [youtubeVideos, setYoutubeVideos] = useState([]);
  const [customerLogos, setCustomerLogos] = useState([]);
  const [aboutMe, setAboutMe] = useState({url: '', description: [], title:''});
  const [loading, setLoading] = useState(false);
  const [footer, setFooter] = useState({textSection: [], copyright: 'Copyright © 2023 CookieLookie Media. All Rights Reserved', video: ''});
  
  const fetchData = async () => {
    const client = SanityConfig;
    try {
      setLoading(true);

      const query =
        '{ "youtubeVideos": *[_type == "portfolioItem"]|order(orderRank), "aboutMe": *[_type == "aboutMeItem"], "footer": *[_type == "footerItem"]{textSection, copyright, "video": videoText.asset->url,}, "customerLogos": *[_type == "customerItem"] }';
      const data = await client.fetch(query);
      setYoutubeVideos(data.youtubeVideos);
      setCustomerLogos(data.customerLogos);
      setAboutMe(data.aboutMe[0]);
      setFooter(data.footer[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("fetching page data");
    fetchData();
  }, []);

  return (
    <DataContext.Provider
      value={{ youtubeVideos: youtubeVideos, loading: loading, aboutMe: aboutMe, customerLogos: customerLogos, footer: footer }}
    >
      {
        <div className={loading ? "showing" : "showing hidden"}>
          <div className="logoLoader">
            <img alt='Logo firmy cookielookie' src={logo} />
          </div>
        </div>
      }
      <div className="App">
        <Header></Header>
        <div className="AboutPage">
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              nodeRef={nodeRef}
              timeout={200}
              classNames="page"
              unmountOnExit
            >
              {(state) => (
                <div ref={nodeRef} className="AboutPage">
                  {currentOutlet}
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
        <Footer></Footer>
      </div>
    </DataContext.Provider>
  );
}

export default MainLayout;
