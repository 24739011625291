import React from "react";
import AboutPage from "./pages/about/aboutPage";
import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import YoutubeGalleryContent from "./pages/portfolio/youtubeGalleryContent";
import PageNotFound from "./pages/pageNotFound/pageNotFound";
import MainLayout from "./components/mainLayout/mainLayout";

export const DataContext = React.createContext({
  loading: true,
  youtubeVideos: [],
  customerLogos: [],
  aboutMe: { url: "", description: [], title: "" },
  footer: {
    textSection: [],
    copyright: "Copyright © 2023 CookieLookie Media. All Rights Reserved",
    video: "",
  },
});

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
     
        <Route path="/" element={<MainLayout />}>
          <Route index element={<AboutPage />} />

          <Route path="/about" element={<AboutPage />} />
          <Route path="/dashboard" element={<YoutubeGalleryContent />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
